import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import ForBusiness from "../components/OurCompany/ForBusiness/ForBusiness";

import Favicon from "../images/Favicon.png";

const ForBusinessPage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>For Business - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="keywords" content="insurance, franchisee business, GPS-enabled, Car Toppers"/>
                <meta name="description"
                      content="Elevate your last mile delivery efficiency with Drivosity's advanced GPS tracking solutions to improve safety and productivity. See significant reductions in auto accidents, insurance claims, and insurance costs. Contact us now."/>
            </Helmet>
            <Layout component={<ForBusiness/>}/>
        </>
    )
}

export default ForBusinessPage;
